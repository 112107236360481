<template>
    <div>
        <div class="img">
            <img src="../../components/xinwen/新闻资讯/1.png" alt="" />
        </div>
        <div class="zou"  >
            <div class="han">搜索结果</div>
            <div class="ying">
                search result
            </div>
        </div>
    <div class="searchContainer">
        <div class="big">搜索结果</div>
        <div class="small">search result</div>


        <div class="container"  >
            <div class="item"
                 @click="btnClick(item.link, i, item)"
                 v-for="(item, i) in detailList"
                 :key="i">
                <div class="title"
                     v-html="delHtmlTag(item.name)"
                ></div>
                <div class="time">{{ item.add_time.slice(0, 10) }}</div>
            </div>
        </div>
        <div class="Pagination">
            <Pagination
                    :total="total"
                    :pageSize="pageSize"
                    :currentSize="currentSize"
                    :pagerCount="pagerCount"
                    @changeSize="changeSize"
            ></Pagination>
        </div>
    </div>
        <bottom></bottom>
    </div>
</template>

<script>
    import Bottom from "../../components/common/Bottom"
  import axios from "axios";
  import Pagination from "./Pagination";
  export default {
    components: {
      Pagination ,
      Bottom
    },
    async created() {
      let data = await axios({
        url: "http://dwj.test.jianong.com/index.php/index/index/getNewsByKeywordList",
        params: {
          keyword: this.$route.query.keyword,
          page: this.currentSize,
          limit: this.pageSize,
        },
      });
      this.total = data.data.data.total;
      this.detailList = data.data.data.data;
      let keyword = this.$route.query.keyword;
      this.detailList = this.detailList.map((e) => {
        e.name = e.name.replace(
            keyword,
            `<span style='color:red;font-weight:bold'>${keyword}</span>`
        );
        return e;
      });
    },
    methods: {
      delHtmlTag(str) {
        // console.log(str)
        // if (str != null || str == undefined  ) {
        //   console.log(str.replace(/<[^>]+>/g, ""))
        //   return str.replace(/<[^>]+>/g, "");
        //   console.log(111);
        if (str != null || str == undefined) {
          let estr = str
          // .replace(/<[^>]+>/g, "")
              .replace(/&lt;/g, "<")
              .replace(/&gt;/g, ">")
              .replace(/&amp;/g, "")
              .replace(/&nbsp;/g, "")
              .replace(/nbsp;/g, "")
              .replace(/&quot;/g, '"');
          // console.log(estr)
          return estr;
        } else {
          // console.log(222);
        }
      },

      async changeSize(newSize, OldSize) {
        this.currentSize = newSize;
        let data = await axios({
          url: "http://dwj.test.jianong.com/index.php/index/index/getNewsByKeywordList",
          params: {
            keyword: this.$route.query.keyword,
            page: this.currentSize,
            limit: this.pageSize,
          },
        });
        this.total = data.data.data.total;
        this.detailList = data.data.data.data;
        let keyword = this.$route.query.keyword;
        this.detailList = this.detailList.map((e) => {
          e.name = e.name.replace(
              keyword,
              `<span style='color:red;font-weight:bold'>${keyword}</span>`
          );
          return e;
        });
        console.log(this.detailList);
      },

      btnClick(link, i, item) {
        if (link == null || link == "") {
          const routeData = this.$router.resolve({
            // name: "juti",
            path:'/juti',
            // query: { key: i, cateid: this.cateid, item: JSON.stringify(item) },
          });
          window.open(routeData.href, "_blank");
          window.localStorage.setItem('item', JSON.stringify(item));
        } else {
        }
      },
      // btnClick(link, i, item) {
      //   console.log(link);
      //   if (link == null || link == "") {
      //     const routeData = this.$router.resolve({
      //       name: "juti",
      //       query: { key: i, cateid: this.cateid, item: JSON.stringify(item) },
      //     });
      //     window.open(routeData.href, "_blank");
      //   } else {
      //   }
      // },
    },
    data() {
      return {
        total: "",
        pageSize: 10,
        currentSize: 1,
        pagerCount: 7,
        detailList: [],
        link: "",
      };
    },
    watch: {
      $route: {
        deep: true,
        handler() {
          this.changeSize();
        },
      },
    },
  };
</script>

<style scoped lang="less">
    .img img {
        width: 100%;
        height: 48.6891vh;
    }
    .zou{
        width: 11.1979vw;
        height: 11.551vh;
        opacity: 0.8;
        background-color: #d7000f;
        position: absolute;
        margin-left: 5.5625vw;
        margin-top: -8.53vh;
    }
    .han{
        width: 10.7083vw;
        height: 1.4063vw;
        font-size: 1.5625vw;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: left;
        color: #ffffff;
        margin-top:0.771vw ;
        margin-left: 1.1vw;
    }
    .ying{
        width: 10.125vw;
        height: 1.1979vw;
        font-size: 1.3vw;
        font-family: PingFang SC, PingFang SC-Light;
        font-weight: 300;
        color: #ffffff;
        margin-left: 1.1vw;
        margin-top: 0.65vw;
        line-height: 1.15vw;
    }


    .searchContainer {
        width: 80vw;
        margin: 0 auto;
        margin-bottom: 6vw;
        .big {
            /*margin-top: 3.125vw;*/
            /*font-size: 1.5625vw;*/
            /*font-family: PingFang SC, PingFang SC-Bold;*/
            /*font-weight: 700;*/
            /*text-align: center;*/
            /*color: #db1f2c;*/

            height: 2.0833vw;
            font-size: 2.2917vw;
            font-family: PingFang SC, PingFang SC-Bold;
            font-weight: 700;
            color: #d7000f;
            margin-top: 4.1146vw;
            text-align: center;
        }
        .small {
            /*margin-top: 0.5625vw;*/
            /*font-size: 1.45vw;*/
            /*font-family: PingFang SC, PingFang SC-Light;*/
            /*font-weight: 300;*/
            /*text-align: center;*/
            /*color: #b3b3b3;*/
            /*margin-bottom: 4vw;*/
            height: 1.0417vw;
            font-size: 1.3542vw;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: left;
            color: #646464;
            margin-top: 1.0417vw;
            text-align: center;
            margin-bottom: 4vw;
        }
        .container {
            .item:hover {
                .title{
                    color: #d7000f;
                }
            }
            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.8625vw 0;
                border-bottom: 0.1042vw solid #e6e6e6;
                .title {
                    flex: 1;
                    font-weight: 700;
                    font-size: 1.4833vw;
                }
                .time {
                    width: 10.2083vw;
                    text-align: right;
                    font-size: 1.1625vw;
                    color: #bbbbbb;
                }
            }
        }
        .Pagination {
            padding: 1.5625vw;
        }
    }
</style>
