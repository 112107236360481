<template>
  <div class="container">

    <div class="div1"><img  class="aaaa" src="../../assets/17.png" alt=""></div>
    <div class="div2">
      <img class="ww" src="../../assets/16.png" alt="">
      <span class="spanp">400-678-9069</span>
    </div>

    <span class="span1" ><img class="bbbb" src="../../assets/wx.png" alt=""></span>
    <div class="span2"><img class="cccc" src="../../assets/wx拷贝.png" alt=""></div>
    <span class="span3">大伟嘉官方微信</span>
    <span class="span4">大伟嘉官方微信</span>
    <div class="span5">京ICP备12018997号-1</div>

    <div class="div3"></div>

    <div class="div4">
      <a href="#/zou/a"><span class="spano">走进大伟嘉</span></a>
      <a href="#/xin"><span class="spano">新闻资讯</span></a>
      <a href="#/chan/first"> <span class="spano">产业布局</span></a>
      <a  href="#/ke/left"><span class="spano">科技创新</span></a>
      <a href="#/ren">  <span class="spano">人才中心</span></a>
      <a href="#/she"><span class="spano">社会责任</span></a>
      <a href="#/lian"> <span class="spano">联系我们</span></a>
    </div>
    <ul class="ul1">
      <a href="#/zou/a"><li>大伟嘉简介</li></a>
      <a href="#/zou/b"><li>董事长介绍</li></a>
      <a href="#/zou/g"> <li>领导关怀</li></a>
      <a href="#/zou/c"><li>企业荣誉</li></a>
      <a href="#/zou/e"><li>产品中心</li></a>
      <a href="#/zou/f"> <li>发展历程</li></a>
      <a href="#/zou/d"> <li>企业文化</li></a>
    </ul>
    <ul class="ul2">
      <a href="#/xin"> <li>大伟嘉新闻</li></a>
      <a href="#/xin"> <li>行业新闻</li></a>
      <a href="#/xin"> <li>媒体视界</li></a>
    </ul>
    <ul class="ul3">
      <a href="#/chan/first"> <li>生物育种</li></a>
      <a href="#/chan/second"><li>生物饲料</li></a>
      <a href="#/chan/third"><li>生物兽药</li></a>
      <a href="#/chan/fourth"> <li>健康养殖</li></a>
      <a href="#/chan/fifth"> <li>产业服务</li></a>
    </ul>
    <ul class="ul4">
      <a href="#/ke/left">  <li>科研创新</li></a>
      <a href="#/ke/center"> <li>科研实力</li></a>
      <a href="#/ke/right">  <li>科研成果</li></a>
    </ul>
    <div class="div5"></div>
    <div class="div6">
      <a class="span" href=""  target="_blank">集团招投标</a>
      <a class="span" href="http://39.106.208.233//esweb/index.aspx"  target="_blank">勤哲</a>
      <a class="span" href="http://mail.vicagroup.com.cn/"  target="_blank">邮箱</a>
      <!--<a class="span" href="http://gs.vicagroup.com.cn/cwbase/web/login.aspx"  target="_blank">费用报销</a>-->
      <a class="span" href="https://www.fxiaoke.com/pc-login/build/login.html"  target="_blank">云平台</a>
      <a class="span" href="https://dwj.study.moxueyuan.com/login/login.html"  target="_blank">伟嘉商学院</a>
      <a class="span" href="http://www.vicagroup.com.cn/index.php/Home/Feedbacks/index.html"  target="_blank">留言信箱</a>
      <a class="span" href="https://uweb.umeng.com/v1/login.php?siteid=1619107"  target="_blank">站长统计</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Bottom"
  }
</script>

<style scoped>
  .container{
    width: 100%;
    height: 55vh;
    background-color: #4F4F4F;
  }
  .div1{
    position: absolute;
    margin-left: 50%;
    margin-top: 6.0524vh;
    transform: translate(-50%,-50%);
  }
 /deep/ .aaaa{
    width: 10vw!important;
    height: 2.5417vw!important;
  }
  .div2{
    position: absolute;
    margin-left: 50%;
    margin-top: 11vh;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;

  }
 /deep/ .ww{
    width: 1.4vw!important;
    height: 1.4vw!important;
  }
  .div2 .spanp{
    height: 2vh;
    font-size: 1.2vw!important;
    font-family: Arial, Arial-Regular;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.088vw;
    margin-left: 0.8854vw;
  }
  .span1{
    position: absolute;
    margin-left:3.2292vw;
    margin-top:18vh;
  }
  /deep/.bbbb{
    width: 6.3021vw!important;
     height: 6.3021vw!important;
  }
  .span2{
    position: absolute;
    margin-left:11.4583vw;
    margin-top: 18vh;
  }
  /deep/ .cccc {
    width: 6.3021vw!important;
    height: 6.3021vw!important;
  }
  .span3{
    position: absolute;
    height: 1.4981vh;
    font-size: 0.9vw!important;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    color: #dddddd;
    margin-left:3.0729vw;
    margin-top: 33vh;
  }
  .span4{
    position: absolute;
    margin-left:11.3021vw;
    margin-top: 33vh;
    height: 1.4981vh;
    font-size: 0.9vw!important;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    color: #dddddd;
  }
  .span5{
    height: 1.6854vh;
    font-size: 1.2vw;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    color: #dddddd;
    position: absolute;
    margin-left:3.0208vw;
    margin-top: 38vh;
  }
  .div3{
    width: 0.0521vw;
    height: 27vh;
    opacity: 0.5;
    background: #ffffff;
    position: absolute;
    margin-left:24.9479vw ;
    margin-top: 18vh;
  }
  .div4{
    position: absolute;
    margin-top: 18vh;
    padding-left: 33.2813vw;
    width: 64.0625vw;
  }
  .div4 .spano {
    font-family: PingFang SC, PingFang SC-Medium;
    padding-right: 3.3854vw!important;
    color: white !important;
    font-weight: 500;
    font-size: 1vw!important;
  }
  .ul1{
    position: absolute;
    margin-top: 22vh;
    margin-left: 33.2813vw;
  }
  li{
    padding-bottom: 1.045vh;
    font-size: 0.8vw;
    color: #A8A186;
    font-family: PingFang SC, PingFang SC-Medium;
  }
  .ul2{
    position: absolute;
    margin-top: 22vh;
    margin-left: 41.5vw;
  }
  .ul3{
    position: absolute;
    margin-top: 22vh;
    margin-left: 49vw;
  }
  .ul4{
    position: absolute;
    margin-top: 22vh;
    margin-left: 56.5vw;
  }

  .div5{
    width: 44.6042vw;
    height: 0.0521vw;
    background: #707070;
    position: absolute;
    margin-left: 48.6458vw;
    margin-top: 45.3184vh;
  }
  .div6{
    width: 42.4479vw;
    position: absolute;
    margin-top: 47vh;
    padding-left: 52.4896vw;
  }
  .div6 .span {
    height: 1.5vh;
    font-size: 0.8vw;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    color: #b5b5b5;
    margin-right: 1.0417vw;
  }
</style>